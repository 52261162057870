$background-color-dark: #333;
$background-color-light: #fff;
$color-secondary: #179dff;

$background-color: $background-color-light;
$text-color: #333;
$text-color-light: #aaa;

:export {
  themeColor: $background-color;
}

@import '../../assets/styles/common.scss';

/* Component Specific Styles */

h1 {
  @apply my-10 text-3xl font-black;
}
h2 {
  @apply mt-10  text-xl font-bold uppercase;
}

ul {
  @apply list-disc pl-5;
}

ol {
  @apply pl-5;
  list-style-type: upper-alpha;
}

p {
  @apply pb-5 leading-loose;
}

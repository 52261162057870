$background-color-dark: #333;
$background-color-light: #fff;
$color-secondary: #179dff;

$background-color: $background-color-light;
$text-color: #333;
$text-color-light: #aaa;

:export {
  themeColor: $background-color;
}

/* Component Specific Styles */
.app {
  @apply px-3;
}
